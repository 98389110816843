import { actActionIfExecuting } from './utils/actActionIfExecuting';
import { addCustomWaterfallIfExecuting } from './utils/addCustomWaterfallIfExecuting';
import { createPlacesPerformanceMarker } from './utils/createPlacesPerformanceMarker';

import type { PerformanceCoreDatapoint } from 'owa-analytics';
import type { CustomData, CustomWaterfallRange } from 'owa-analytics-types';
import type { TraceErrorObject } from 'owa-trace';

type StepToWaterfallIndexMap = Record<string, CustomWaterfallRange>;

/**
 * Abstract base class for Places Analytics Core Events.
 * Provides methods to start, complete, and cancel performance datapoints,
 * as well as add custom data and checkpoints.
 *
 * @template T - A map of step names to custom waterfall ranges.
 */
export abstract class PlacesAnalyticsCoreEventBase<T extends StepToWaterfallIndexMap> {
    protected performanceDatapoint?: PerformanceCoreDatapoint;
    private shouldRunStart = true;
    private shouldInitializeOnce = false;

    constructor(shouldInitializeOnce: boolean = false) {
        this.shouldInitializeOnce = shouldInitializeOnce;
    }

    /**
     * Creates a new performance core datapoint.
     * Must be implemented by subclasses to return a new instance of PerformanceCoreDatapoint.
     *
     * @returns {PerformanceCoreDatapoint} The new performance core datapoint.
     */
    protected abstract createNewPerformanceCoreDatapoint(): PerformanceCoreDatapoint;

    /**
     * Gets the map of steps to custom waterfall ranges.
     * Must be implemented by subclasses.
     *
     * @returns {T} The map of steps to custom waterfall ranges.
     */
    protected abstract getStepToWaterfallIndexMap(): T;

    /**
     * Starts the performance datapoint and adds optional custom data.
     *
     * @param {CustomData} [customData] - Optional custom data to add to the datapoint.
     * @returns {PerformanceCoreDatapoint | undefined} The performance datapoint.
     */
    start(customData?: CustomData): PerformanceCoreDatapoint | undefined {
        if (this.shouldInitializeOnce && !this.shouldRunStart) {
            return this.performanceDatapoint;
        }

        this.shouldRunStart = false;
        const dp = this.createNewPerformanceCoreDatapoint();
        this.performanceDatapoint = createPlacesPerformanceMarker(dp);
        if (customData) {
            this.performanceDatapoint.addCustomData(customData);
        }
        return this.performanceDatapoint;
    }

    /**
     * Completes the performance datapoint with the given parameters.
     *
     * @param {Object} params - The parameters for completion.
     * @param {boolean} params.loadSuccessful - Whether the load was successful.
     * @param {string} [params.reason] - Optional reason for completion.
     * @param {string} [params.errorSource] - Optional source of the error.
     * @param {TraceErrorObject} [params.errorObject] - Optional error object.
     */
    complete({
        loadSuccessful,
        reason = '',
        errorSource = '',
        errorObject,
        customData,
    }: {
        loadSuccessful: boolean;
        reason?: string;
        errorSource?: string;
        errorObject?: TraceErrorObject;
        customData?: CustomData;
    }) {
        const errorSourceObj = loadSuccessful
            ? {}
            : { errorSource: errorSource || 'EmptyErrorSourcePleaseUpdate' };

        actActionIfExecuting(
            this.performanceDatapoint,
            {
                loadSuccessful_1: loadSuccessful,
                ...(reason ? { reason } : {}),
                ...errorSourceObj,
                ...customData,
            },
            loadSuccessful ? 'end' : 'endWithError',
            errorObject
        );
    }

    /**
     * Cancels the performance datapoint with an optional reason.
     *
     * @param {string} [reason] - Optional reason for cancellation.
     */
    cancel(reason: string = '') {
        actActionIfExecuting(
            this.performanceDatapoint,
            {
                reason,
            },
            'invalidate'
        );
    }

    /**
     * Adds a checkpoint to the performance datapoint.
     *
     * @param {K} checkpoint - The checkpoint to add.
     * @param {CustomData} [customData] - Optional custom data to add to the checkpoint.
     */
    addCheckmark<K extends keyof T>(checkpoint: K, customData?: CustomData) {
        const stepToWaterfallMap = this.getStepToWaterfallIndexMap();
        addCustomWaterfallIfExecuting(
            this.performanceDatapoint,
            stepToWaterfallMap[checkpoint],
            checkpoint as string,
            customData
        );
    }

    /**
     * Adds custom data to the performance datapoint.
     *
     * @param {CustomData} customData - The custom data to add.
     */
    addCustomData(customData: CustomData) {
        actActionIfExecuting(this.performanceDatapoint, customData);
    }
}
