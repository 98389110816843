import {
    placesAnalyticsTabLoadAction,
    placesAnalyticsAppLoadAction,
    cancelPlacesAnalyticsPerfEventsAction,
} from 'places-fwk-actions';
import { orchestrator } from 'satcheljs';
import {
    placesAnalyticsTabLoadE2E,
    type STEP_TO_WATERFALL_INDEX_MAP,
} from '../placesAnalyticsTabLoadE2E';

/**
 * Orchestrates various scenarios for the places analytics tab load events.
 * Handles checkpoints, errors, and completion markers based on the scenario.
 */
orchestrator(
    placesAnalyticsTabLoadAction,
    ({ scenario, tabId, prevTabId, customData, errorSource, errorObject }) => {
        let checkpoint: keyof typeof STEP_TO_WATERFALL_INDEX_MAP | undefined;
        let reason: string | undefined;
        let shouldEndSuccessMarker = false;

        switch (scenario) {
            case 'HomePageLoadBegin':
                // Keeping the start of the perf marker to the homepage load for now.
                // This will be updated once we have the tab switch perf implementation.
                placesAnalyticsTabLoadE2E.start({
                    currentTabId: tabId,
                    prevTabId,
                    ...customData,
                });
                placesAnalyticsAppLoadAction({
                    scenario: 'HomePageLoadBegin',
                    customData,
                });
                return;

            case 'HomePageActionableInsightsLoaded':
            case 'HomePageSummaryBarLoaded':
            case 'HomePageSummaryCardLoaded':
                checkpoint = scenario;
                break;

            case 'HomePageLoadEnd':
                shouldEndSuccessMarker = true;
                reason = 'completed-because-homepage-loaded';
                placesAnalyticsAppLoadAction({
                    scenario: 'HomePageLoadEnd',
                    customData,
                });
                break;

            case 'TabSwitch':
                // This is to handle the case when user switches the tab immediately after landing on space-analytics page.
                // In this case, we should cancel the perf markers for 'admin role switch' and 'app load' if they are already running.
                cancelPlacesAnalyticsPerfEventsAction({
                    scenario: 'TabSwitch',
                    eventsToCancel: [
                        'PlacesAnalyticsAppLoadE2E',
                        'PlacesAnalyticsAdminRoleSwitchE2E',
                    ],
                });

                // TODO: cancelling this event for now. Need to revisit this during the tab switch perf implementation
                placesAnalyticsTabLoadE2E.cancel('TabSwitch');
                break;
        }

        if (checkpoint) {
            placesAnalyticsTabLoadE2E.addCheckmark(checkpoint, customData);
        }

        if (errorSource || errorObject) {
            placesAnalyticsTabLoadE2E.complete({
                loadSuccessful: false,
                errorSource: errorSource ?? scenario,
                errorObject,
            });
            return;
        }

        if (shouldEndSuccessMarker) {
            placesAnalyticsTabLoadE2E.complete({
                loadSuccessful: true,
                reason,
            });
        }
    }
);
