import {
    getQueryStringParameter,
    hasQueryStringParameter,
    nodeParseQueryString,
} from 'owa-querystring';
import { isNullOrWhiteSpace } from 'owa-string-utils';
import { lazyGetContext } from 'owa-metaos-app-bootstrap';
import { logGreyError } from 'owa-analytics';

export default function getEntryPoint(qspOverride?: string): string | null {
    // Check QSP override first. this is split out in case nodeParseQueryString(qspOverride)?.ep returns empty, then we'll fallback to window QSP.
    const qspEpIndex = qspOverride?.indexOf('ep') || -1;
    const qspEpParamString =
        qspOverride && qspEpIndex > -1 ? qspOverride.slice(qspEpIndex) : qspOverride;

    const qspoverrideEntryPoint = qspEpParamString
        ? nodeParseQueryString(qspEpParamString)?.ep
        : undefined;
    let entryPoint = qspoverrideEntryPoint ? qspoverrideEntryPoint : getQueryStringParameter('ep');
    if (
        (entryPoint == undefined || isNullOrWhiteSpace(entryPoint)) &&
        hasQueryStringParameter('guidanceId')
    ) {
        // check for guidance id in the query string, this is a stop-gap measure as guidance deeplinks has no 'ep' qparam
        entryPoint = 'guidanceInvite';
    }
    return entryPoint;
}

export async function getMetaOsEntryPoint(): Promise<string | null> {
    try {
        // subPageId is used for relative link to support deeplinking.
        // so, we can reuse this to extrct the QSP of the relative link.
        // Example format: https://teams.microsoft.com/l/entity/bae38e8e-7f76-4a31-9bb6-22b75f6dd1bc?context=%7b%22subEntityId%22%3A%22%2F%3Fep%3DsamuleEmailSig%26utm_source%3DsamuleEmailSig%22%7dhttps://teams.microsoft.com/l/entity/bae38e8e-7f76-4a31-9bb6-22b75f6dd1bc?context=%7b%22subEntityId%22%3A%22%2F%3Fep%3DsamuleTest%26utm_source%3DsamuleTest%22%7d
        const context = await lazyGetContext.importAndExecute();
        return context.page.subPageId ? getEntryPoint(context.page.subPageId) : null;
    } catch (e) {
        logGreyError('getMetaOsEntryPoint', e);
        return null;
    }
}
